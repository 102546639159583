import React from 'react';
import styled, { css } from 'styled-components';
import { ifProp, prop } from 'styled-tools';
import Layout from 'components/Layout';
import SectionsWrapper from 'components/SectionsWrapper';
import { useLayoutContext } from 'context/LayoutContext';
import ReadyToTrade from 'components/ReadyToTrade';
import SmallPrint from 'components/SmallPrint';
import Section from 'components/Section';
import SEO from 'components/SEO';

const PolicyWrapper = styled(SectionsWrapper)`
  margin-top: 6.5625rem;

  ${ifProp('$showAlert', css`
    margin-top: 9.5625rem;
  `)}

  @media (min-width: ${prop('theme.breakpoints.lg')}) {
    margin-top: 10.0625rem;

    ${ifProp('$showAlert', css`
      margin-top: 13.0625rem;
    `)}
  }

  ${SmallPrint} {
    font-style: normal;
  }
`

const HowToFundSection = styled(Section)`
  padding-top: 2rem;
  padding-bottom: 2rem;

  @media (min-width: ${prop('theme.breakpoints.sm')}) {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
`;

const StyledDiv = styled.div`
  .step-wrapper {
    display: flex;
    font-size: 1.125rem;
    font-weight: 700;

    p {
      font-size: 1.125rem;
      font-weight: 700;
      text-transform: uppercase;
    }

    .step-number {
      color: ${prop('theme.colors.primaryRed')};
      margin-right: 0.3125rem;
    }
  }
`;


const ContactText = styled(SmallPrint)`
  margin-bottom: 0;
`;

const SectionTitle = styled.p`
  font-size: 1.125rem;

  .color-red {
    color: ${prop('theme.colors.primaryRed')};
    margin-right: 0.3125rem;
  }

  &&& {
    font-weight: 700;
  }
`;

function ExternalComplaintsPolicy() {
  const { state: layoutContextState } = useLayoutContext();
  const { showAlert } = layoutContextState;

  return (
    <Layout>
      <SEO title='External Complaints Policy' />
      <PolicyWrapper noGutters $showAlert={showAlert}>
        <HowToFundSection withPadding>
          <StyledDiv>
            <SectionTitle>Additional information on our complaints procedure.</SectionTitle>
            <SmallPrint dark>
              In the unlikely event of you having any reason to feel dissatisfied with any aspect of our
              service, in the first instance you should email us at <a href='mailto:support@tastytrade.au'>support@tastytrade.au</a> or call +61 2 8317 1402,
              as the vast majority of complaints can be dealt with at this level.
            </SmallPrint>
            <SmallPrint dark>
              If our Support staff are unable to resolve the matter you may refer it as a complaint to our
              Compliance Department. Please set out the complaint clearly in writing for the attention of the
              Compliance Department. You can send the complaint to <a href='mailto:compliance@tastytrade.au'>compliance@tastytrade.au.</a> The Compliance
              Department will carry out an impartial review of the complaint with a view to understanding what did
              or did not happen and to assess whether we have acted fairly within our rights and have met our contractual
              and other obligations. A full written response will be provided within 30 days of receiving the complaint and
              often much sooner.
            </SmallPrint>
            <SmallPrint dark>
              If you do not feel your complaint has been resolved to your complete satisfaction you may then refer the
              matter to our independent external resolution authority. Australian clients should refer their dispute to
              the Australian Financial Complaints Authority (AFCA).
            </SmallPrint>
            <SmallPrint dark>
              AFCA is an independent organisation established to resolve disputes between financial institutions and
              their customers. You should note that AFCA will not consider a complaint until we have had the opportunity
              to address the complaint, and any reference to AFCA cannot be made by you until you receive a final
              response from us or 30 days after the date of your complaint, whichever is sooner.
            </SmallPrint>
            <SectionTitle>Contact AFCA:</SectionTitle>
            <ContactText dark>Australian Financial Complaints Authority</ContactText>
            <ContactText dark>Melbourne</ContactText>
            <ContactText dark>VIC 3001</ContactText>
            <ContactText dark>Free call: 1800 931 678</ContactText>
            <ContactText dark>Email: <a href='mailto:info@afca.org.au'>info@afca.org.au</a></ContactText>
            <ContactText dark>Further information can also be found at <a href='https://www.afca.org.au/' target='_blank'>www.afca.org.au</a></ContactText>
          </StyledDiv>
        </HowToFundSection>
        <ReadyToTrade />
      </PolicyWrapper>
    </Layout>
  )
}

export default ExternalComplaintsPolicy;
